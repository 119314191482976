import React, { FC, MouseEvent, ReactNode } from "react";
import { ButtonType, Link as KLLink, LinkIconAnimation } from "@qwilr/kaleidoscope";
import { navigate } from "gatsby";

interface LinkProps {
  href?: string;
  rel?: string;
  target?: string;
  className?: string;
  onClick?: (event?: any) => void;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  iconAnimation?: LinkIconAnimation;
  id?: string;
}

const Link: FC<LinkProps> = ({ href = "", onClick, ...props }) => {
  const isInternal = !href.includes("://");

  const handleNav = (event: any): void => {
    event.stopPropagation();
    onClick?.(event);

    const isMacOS = navigator.appVersion.includes("Mac");

    if (event.button === 0 && (isMacOS ? event.metaKey : event.ctrlKey)) {
      window.open(href, "_blank");
      return;
    }

    navigate(href);
  };

  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    if (event.button === 1) {
      event.preventDefault();
      window.open(href, "_blank");
      return;
    }
  };

  if (isInternal) {
    return (
      <span onMouseDown={handleMouseDown}>
        <KLLink onClick={handleNav} {...props} />
      </span>
    );
  }

  return <KLLink href={href} onClick={onClick} {...props} />;
};

export default Link;
